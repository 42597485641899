import React, { useState } from 'react';
import MaskedFormControl from 'react-bootstrap-maskedinput'
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';

import consts from '@project/consts';
import Api from "@project/Api";

const emailRegular = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const FeedbackModal = (props) => {
    const { show, feedbackTypes, onSuccess, onClose } = props;
    const [error, setError] = useState('');
    const [uploading, setUploading] = useState(false);
    const [form, setForm] = useState({
        name: '',
        message: '',
        phone: '',
        email: '',
        attachments: [],
        type_id: 1,
        agreement: false,
    });

    return (
        <Modal
            size="lg"
            show={show}
            centered
            onHide={onClose}
        >
            <Form>
                <Modal.Header>
                    <Modal.Title className="text-center">
                        Мы будем рады узнать Ваше мнение о нашей компании!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="mb-3">
                            <Col md="6">
                                <Form.Group controlId="name">
                                    <Form.Label>Ваше имя</Form.Label>
                                    <Form.Control
                                        type="text"
                                        value={form.name}
                                        placeholder="Имя"
                                        disabled={uploading}
                                        onChange={({ target }) => setForm({
                                            ...form,
                                            name: target.value
                                        })}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md="6">
                                <Form.Group controlId="phone">
                                    <Form.Label>Контактный телефон</Form.Label>
                                    <MaskedFormControl
                                        type="text"
                                        mask="+7 (111) 111-11-11"
                                        disabled={uploading}
                                        onChange={({ target }) => setForm({
                                            ...form,
                                            phone: target.value
                                        })}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Form.Group controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        value={form.email}
                                        disabled={uploading}
                                        placeholder="email@example.com"
                                        onChange={({ target }) => setForm({
                                            ...form,
                                            email: target.value.trim().replaceAll(' ', '')
                                        })}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <Form.Control
                                    as="textarea"
                                    placeholder="Отзыв"
                                    rows={5}
                                    disabled={uploading}
                                    onChange={({ target }) => setForm({
                                        ...form,
                                        message: target.value
                                    })}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="6">
                                <Form.Group className="d-grid" controlId="file">
                                    <Form.Label
                                        className={`btn btn-outline-primary${uploading ? ' disabled' : ''}`}
                                    >Прикрепить файлы</Form.Label>
                                    <Form.Control
                                        hidden
                                        type="file"
                                        name="file"
                                        accept="image/png, image/jpg, .png"
                                        disabled={uploading}
                                        onChange={(event) => {
                                            const body = new FormData(event.target.form);

                                            setError('');
                                            setUploading(true);
                                            Api.post(consts.API_METHODS.FILE_UPLOAD, { body })
                                                .then(attachments => setForm({
                                                    ...form,
                                                    attachments: [
                                                        ...form.attachments,
                                                        ...attachments
                                                    ]
                                                }))
                                                .catch(err => setError(err.message))
                                                .finally(() => setUploading(false));
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md="6">
                                Размер файла не должен превышать 15 Мб. Допустимые разрешения файла: png jpg pdf
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="d-flex">
                                {form.attachments.map(attachment => (
                                    <div className="mr-2" key={attachment.path}>
                                        <Badge rounded="pill">{attachment.name}</Badge>
                                        <Button
                                            size="sm"
                                            variant=""
                                            onClick={() => setForm({
                                                ...form,
                                                attachments: form.attachments.filter(({ path }) => path !== attachment.path)
                                            })}
                                        >x</Button>
                                    </div>
                                ))}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col sm="auto">
                                <Form.Select
                                    value={form.type_id}
                                    disabled={uploading}
                                    onChange={event => setForm({ ...form, type_id: +event.target.value })}
                                >
                                    {(feedbackTypes || []).map(type => (
                                        <option value={type.id} key={type.id}>{type.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="agreement">
                                    <Form.Check type="checkbox">
                                        <Form.Check.Input
                                            type="checkbox"
                                            checked={form.agreement}
                                            disabled={uploading}
                                            onChange={() => setForm({
                                                ...form,
                                                agreement: !form.agreement
                                            })}
                                        />
                                        <Form.Check.Label>
                                            Согласен на обработку <a href="https://nn.avtosushi.ru/content/2177/politika-obrabotki-personalnyh-dannyh" target="_blank">персональных данных</a>
                                        </Form.Check.Label>
                                    </Form.Check>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <p className="text-danger p-0">{error}</p>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col md="6" className="d-grid gap-2 mb-2 mb-md-0">
                                <Button
                                    variant="primary"
                                    disabled={!form.agreement || !form.message.length || !form.name.length || form.phone.length !== 18 || !form.email.length}
                                    onClick={() => {
                                        setError('');

                                        if (!form.email.toLowerCase().match(emailRegular)) {
                                            setError('Невалидный email');

                                            return;
                                        }

                                        onSuccess(form);
                                    }}
                                >Отправить отзыв</Button>
                            </Col>
                            <Col md="6" className="d-grid">
                                <Button
                                    variant="secondary"
                                    onClick={onClose}
                                >Назад</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default FeedbackModal;
