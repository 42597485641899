import React, { useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';

const PromoCodeModal = (props) => {
    const { show, code, description, onSuccess, onClose } = props;
    const [copyDisabled, setCopyDisabled] = useState(false);
    const [copyTooltip, setCopyTooltip] = useState('');
    const buttonEl = useRef(null);

    return (
        <>
            <Modal
                size="sm"
                show={show}
                centered
                onHide={onClose}
            >
                <Modal.Header>
                    <Modal.Title className="h5 text-center">
                        Спасибо, что выбрали сеть кафе “Автосуши Автопицца”!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="justify-content-center mb-2">
                            <Col xs="auto">
                                <Image src="/resources/images/promo-code.png" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="display-5 fw-bold text-primary text-center">
                                    {code || ''}
                                </p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xs="auto">
                                <Button
                                    ref={buttonEl}
                                    variant="outline-primary"
                                    size="sm"
                                    disabled={copyDisabled}
                                    onClick={() => {
                                        navigator.clipboard.writeText(code)
                                            .then(() => setCopyTooltip('Скопировано'))
                                            .catch(() => setCopyTooltip('Ошибка копирования'))
                                            .finally(() => setTimeout(() => setCopyTooltip(''), 2000));
                                    }}
                                >Копировать</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p className="text-center">
                                    {description || ''}
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col className="d-grid gap-2">
                                <Button
                                    variant="primary"
                                    onClick={onSuccess}
                                >Спасибо</Button>
                                <Button
                                    variant="secondary"
                                    onClick={onClose}
                                >Назад</Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            <Overlay target={buttonEl.current} show={!!copyTooltip} placement="right">
                {(props) => (
                    <Tooltip id="overlay-example" {...props}>{copyTooltip}</Tooltip>
                )}
            </Overlay>
        </>
    );
};

export default PromoCodeModal;
