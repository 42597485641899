import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormCheck from 'react-bootstrap/FormCheck';
import FormControl from 'react-bootstrap/FormControl';

import styles from './styles.scss';

const TipsModal = (props) => {
    const {
        show,
        amounts = [],
        url,
        error,
        pending,
        onSuccess,
        onClose
    } = props;

    const [payment, setPayment] = useState({ amount: 0 });

    useEffect(() => {
        if (amounts.length) {
            setPayment({ amount: amounts[0].value})
        }
    }, [show]);

    return (
        <Modal
            size="sm"
            show={show}
            centered
            scrollable
            onHide={onClose}
        >
            <Modal.Header>
                <Container>
                    <Row>
                        <Col>
                            <Modal.Title className="h5 text-center">Вы можете оставить чаевые нашему курьеру</Modal.Title>
                        </Col>
                    </Row>
                </Container>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            Выберите сумму чаевых
                        </Col>
                    </Row>
                    <Row className={`${styles.tips} justify-content-around`}>
                        {amounts.map(amount => (
                            <Col
                                xs="auto"
                                key={amount.text}
                            >
                                <Button
                                    className={`rounded-circle ${styles.button}`}
                                    variant={amount.value === payment.amount ? 'primary' : 'outline-secondary'}
                                    disabled={pending}
                                    onClick={() => setPayment({ ...payment, amount: amount.value })}
                                >{amount.text}</Button>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container fluid>
                    <Row>
                        <Col className="d-grid gap-3">
                            {!url && !error && <Button
                                variant="primary"
                                disabled={pending}
                                onClick={() => onSuccess(payment)}
                            >Продолжить</Button>}
                            <Button
                                variant="secondary"
                                disabled={pending}
                                onClick={onClose}
                            >Назад</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
};

export default TipsModal;
