import React, { useState, useEffect } from 'react';
import Spinner from "react-bootstrap/Spinner";
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { YMaps, Map, Placemark } from 'react-yandex-maps';

let timer;
const OrderTracking = (props) => {
    const { show, getCoordinates, onClose } = props;
    const [coordinates, setCoordinates] = useState([]);

    useEffect(() => {
        getCoordinates().then(coordinates => setCoordinates(coordinates));
        timer = setInterval(() => getCoordinates().then(coordinates => setCoordinates(coordinates)), consts.COORDINATES_UPDATE_TIMEOUT);

        return () => clearTimeout(timer);
    }, [coordinates]);

    return (
        <Modal
            size="lg"
            show={show}
            centered
            onHide={onClose}
        >
            <Modal.Header>
                <Modal.Title className="h5 text-center">Отслеживание</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            {!coordinates[0]
                                ? (
                                    <YMaps>
                                        <Map
                                            width="100%"
                                            height="400px"
                                            state={{
                                                center: coordinates,
                                                zoom: 15
                                            }}
                                            defaultState={{
                                                center: coordinates,
                                                zoom: 15,
                                                controls: ['zoomControl', 'fullscreenControl']
                                            }}
                                        >
                                            <Placemark
                                                defaultGeometry={coordinates}
                                                geometry={coordinates}
                                                options={{
                                                    iconLayout: 'default#image',
                                                    iconImageHref: '/resources/images/placemark.svg'
                                                }}
                                            />
                                        </Map>
                                    </YMaps>
                                )
                            : <Spinner animation="border" />}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col className="d-grid gap-2">
                            <Button
                                variant="secondary"
                                onClick={onClose}
                            >Назад</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
};

export default OrderTracking;
