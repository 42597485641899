import React from 'react';

import * as MODAL_TYPES from '@project/consts/modalTypes';

import AlertModal from "@project/components/Modals/Alert";
import TipsModal from "@project/components/Modals/Tips";
import PromoCodeModal from "@project/components/Modals/PromoCode";
import FeedbackModal from "@project/components/Modals/Feedback";
import OrderStatusModal from '@project/components/Modals/OrderStatus';
import OrderTrackingModal from '@project/components/Modals/OrderTracking';

const modalTypeMap = {
    [MODAL_TYPES.ALERT]: AlertModal,
    [MODAL_TYPES.TIPS]: TipsModal,
    [MODAL_TYPES.PROMO_CODE]: PromoCodeModal,
    [MODAL_TYPES.FEEDBACK]: FeedbackModal,
    [MODAL_TYPES.ORDER_STATUS]: OrderStatusModal,
    [MODAL_TYPES.ORDER_TRACKING]: OrderTrackingModal
};

const Modal = (props) => {
    const { type, setShownModal } = props;
    const ModalCompoent = modalTypeMap[type];

    if (!ModalCompoent) return null;

    return <ModalCompoent
        {...props.props}
        show={!!type}
        onClose={() => setShownModal()}
    />
};

export default Modal;
