import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';

import styles from './styles.scss';

const OrderPosotionCard = props => (
    <div className="d-flex align-items-center">
        <div className="me-3"><Image src={props.image_url || '/resources/images/fallback-image.png'} className={styles.img}/></div>
        <div className="d-flex flex-fill justify-content-between align-items-center">
            <div className="lh-1 small me-1">
                <p className="mb-1">{props.name || ''}</p>
                <span className="text-muted">{props.count}</span>
            </div>
            <div className="text-nowrap"><b>{props.sum}</b></div>
        </div>
    </div>
);

const OrderModal = (props) => {
    const {
        show,
        order,
        updateOrder,
        getCoordinates,
        courierCall,
        onClose,
        setShownModal
    } = props;
    const [pending, setPending] = useState(false);
    const {
        order_number: orderNumber,
        status_name: statusName,
        order_sum: orderSum,
        order_date: orderDate,
        order_delivery: orderDelivery,
        order_time: orderTime,
        courier,
        order_items: orderItems,
        courier_call_show: courierCallShow,
        tracking_show: trackingShow
    } = order || {};

    return (
        <Modal
            size="lg"
            show={show}
            centered
            onHide={onClose}
        >
            <Modal.Header className="justify-content-center flex-column">
                <Modal.Title>
                    Статус заказа: {statusName}
                    <Button
                        className="p-2 fs-5 ms-2"
                        disabled={pending}
                        variant="outline-primary"
                        onClick={() => updateOrder()
                            .then(() => setPending(true))
                            .finally(() => setPending(false))}
                    >
                        <i className="bi bi-arrow-repeat"></i>
                    </Button>
                </Modal.Title>
                <hr className="mb-0 w-100" />
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row className="justify-content-between mb-1">
                        <Col xs="auto"><b>{orderNumber}</b></Col>
                        <Col xs="auto"><b>{orderSum}</b></Col>
                    </Row>
                    {orderDate && <Row className="justify-content-between mb-1 small">
                        <Col sm="auto">{orderDate}</Col>
                        <Col sm="auto">{orderDelivery}</Col>
                    </Row>}
                    {courier && <Row className="justify-content-between small">
                        <Col sm="auto">Время доставки {orderTime}</Col>
                        <Col sm="auto">{courier}</Col>
                    </Row>}
                    <Row><Col><hr /></Col></Row>
                    <Row><Col>Состав заказа</Col></Row>
                    {(orderItems || []).map(item => (
                        <Row className="mb-1"><Col><OrderPosotionCard {...item} /></Col></Row>
                    ))}
                    <Row className="mt-4">
                        <Col className="d-grid gap-3 d-sm-block">
                            {courierCallShow && <Button
                                className="me-sm-2"
                                disabled={pending}
                                variant="outline-primary"
                                onClick={() => {
                                    setPending(true);
                                    courierCall()
                                        .catch(err => console.log('Courier call error', err))
                                        .finally(() => setPending(false));
                                }}
                            >Позвонить курьеру</Button>}
                            {false && <Button
                                disabled={pending}
                                variant="outline-primary"
                            >Чат с поддержкой</Button>}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col xs={6} className="d-grid">
                            {trackingShow && <Button
                                disabled={pending}
                                variant="primary"
                                onClick={() => setShownModal({
                                    type: consts.MODAL_TYPES.ORDER_TRACKING,
                                    props: {
                                        onClose: () => setShownModal({
                                            type: consts.MODAL_TYPES.ORDER_STATUS,
                                            order,
                                            updateOrder,
                                            setShownModal,
                                            getCoordinates
                                        })
                                    }
                                })}
                            >Отслеживание</Button>}
                        </Col>
                        <Col xs={6} className="d-grid">
                            <Button
                                disabled={pending}
                                variant="secondary"
                                onClick={onClose}
                            >Назад</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
};

export default OrderModal;
