import React, {useState, useEffect} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
// TODO: перенести в компоненты и надо как то там менять соответствующий scss
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import Api from "@project/Api";
import consts from "@project/consts";

import Modal from '@project/components/Modal';

import "./bootstrap.custom.scss";
import "bootstrap-icons/font/bootstrap-icons.scss";
import styles from "./styles.scss";

export default () => {
    const [appSettings, setAppSettings] = useState({
        orderId: location.pathname.substr(location.pathname.lastIndexOf("/") + 1),
        order: { show: false },
        check: {show: false},
        tips: {show: false, available_sum: []},
        feedback: {show: false},
        promo_code: {show: false},
        feedbackTypes: []
    });
    const [order, setOrder] = useState();
    const [initPending, setInitPending] = useState(true);
    const [pending, setPending] = useState(false);
    const [shownModal = {}, setShownModal] = useState();
    const fetchOrder = () => Api.get(consts.API_METHODS.ORDER_STATUS,
        { query: { id: appSettings.orderId }
    });
    const getCourierCoordinates = () => Api.get(consts.API_METHODS.ORDER_COORDINATES, {
        query: { id: appSettings.orderId }
    });

    useEffect(() => {
        Promise.all([
            Api.get(consts.API_METHODS.SETTINGS_INIT, {
                query: {id: appSettings.orderId}
            }),
            Api.get(consts.API_METHODS.FEEDBACK_TYPES)
        ])
            .then(([data, feedbackTypes]) => setAppSettings({...appSettings, ...data, feedbackTypes}))
            .catch(err => console.error("SETTINGS_INIT", err))
            .finally(() => setInitPending(false));
    }, []);

    return (
        <Container fluid className="d-flex flex-column vh-100 overflow-hidden">
            <Row className="justify-content-center bg-black py-2">
                <Col xs="auto">
                    <Image src="/resources/images/ru-logo-nav 1.png"/>
                </Col>
            </Row>
            <Row className="justify-content-center align-items-center flex-grow-1">
                {initPending && (
                    <Col xs="auto">
                        <Spinner className={styles.spinner} animation="border"/>
                    </Col>
                )}
                {!initPending && (
                    <Col>
                        <Row className="justify-content-center mb-5">
                            <Col xs={11} md={7} lg={6} xl={5} xxl={4}>
                                {appSettings.check?.show ? (
                                    <h2 className="h4 text-center">
                                        Спасибо, что выбрали Автосуши Автопиццу! Выберите
                                        интересующий Вас пункт.
                                    </h2>
                                ) : (
                                    <h2 className="h4 text-center">
                                        Спасибо, что выбрали Автосуши Автопиццу! К сожалению данных
                                        по заказу еще нету. Попробуйте позже
                                    </h2>
                                )}
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col
                                xs={10}
                                sm={6}
                                md={5}
                                lg={4}
                                xl={3}
                                xxl={2}
                                className="d-grid gap-3"
                            >
                                {appSettings.order?.show && (
                                    <Button
                                        variant="primary"
                                        disabled={pending}
                                        onClick={() => {
                                            setPending(true);
                                            fetchOrder()
                                                .then(order => {
                                                    setOrder(order);
                                                    setShownModal({
                                                        type: consts.MODAL_TYPES.ORDER_STATUS,
                                                        props: {
                                                            order,
                                                            setShownModal,
                                                            updateOrder: () => fetchOrder()
                                                                .then(order => setOrder(order))
                                                                .catch(() => setShownModal({
                                                                    type: "alert",
                                                                    props: {
                                                                        title: "Статус заказа",
                                                                        message: 'Ошибка обновления заказа. Попробуйте ещё раз.'
                                                                    }
                                                                })),
                                                            getCoordinates: getCourierCoordinates,
                                                            courierCall: () => Api.get(consts.API_METHODS.ORDER_CALL, {
                                                                query: { id: appSettings.orderId }
                                                            })
                                                        }
                                                    });
                                                })
                                                .catch(err => setShownModal({
                                                    type: consts.MODAL_TYPES.ALERT,
                                                    props: {
                                                        title: "Статус заказа",
                                                        message: "Статус заказа ещё не получен. Повторите попытку немного позже."
                                                    }
                                                }))
                                                .finally(() => setPending(false))
                                            ;
                                        }}
                                    >Статус заказа</Button>
                                )}
                                {appSettings.check?.show && (
                                    <Button
                                        variant="secondary"
                                        disabled={pending}
                                        onClick={() => Api.get(consts.API_METHODS.CHECK_URL, {
                                            query: { id: appSettings.orderId }
                                        })
                                            .then(result => window.location.href = result.url)
                                            .catch(err => setShownModal({
                                                type: consts.MODAL_TYPES.ALERT,
                                                props: {
                                                    title: "Электронный чек",
                                                    message: "Чек ещё не сформирован. Повторите попытку немного позже."
                                                }
                                            }))
                                        }
                                    >Получить чек</Button>
                                )}
                                {appSettings.feedback?.show && (
                                    <Button
                                        variant="secondary"
                                        disabled={pending}
                                        onClick={() => setShownModal({ type: consts.MODAL_TYPES.FEEDBACK,
                                            props: {
                                                feedbackTypes: appSettings.feedbackTypes
                                            }})}
                                    >Оставить отзыв</Button>
                                )}
                                {appSettings.tips?.show && (
                                    <Button
                                        variant="secondary"
                                        disabled={pending}
                                        // onClick={() => setShownModal({ type: consts.MODAL_TYPES.TIPS })}
                                        onClick={() => Api.get(consts.API_METHODS.TIPS_URL, {
                                            query: { id: appSettings.orderId }
                                        })
                                            .then(result => window.location.href = result.url)
                                            .catch(err => setShownModal({
                                                type: consts.MODAL_TYPES.ALERT,
                                                props: {
                                                    title: 'Чаевые',
                                                    message: 'Произошла ошибка. Повторите попытку немного позже.'
                                                }
                                            }))}
                                    >Поблагодарить курьера</Button>)}
                                {appSettings.promo_code?.show && (
                                    <Button
                                        variant="secondary"
                                        disabled={pending}
                                        onClick={() => Api.get(consts.API_METHODS.PROMO_URL, {
                                            query: {id: appSettings.orderId}
                                        })
                                            .then(result => setShownModal({
                                                type: consts.MODAL_TYPES.PROMO_CODE,
                                                props: {
                                                    code: result.code,
                                                    description: result.description
                                                }
                                            }))
                                            .catch(err => setShownModal({
                                                type: consts.MODAL_TYPES.ALERT,
                                                props: {
                                                    title: 'Промо',
                                                    message: 'Произошла ошибка. Повторите попытку немного позже.'
                                                }
                                            }))}
                                    >Промокод на подарок</Button>
                                )}
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
            <Modal
                {...shownModal}
                setShownModal={setShownModal}
            />
        </Container>
    );
};
