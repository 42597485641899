export const SETTINGS_INIT = 'settings/init';
export const CHECK_URL = 'check/url';
// export const TIPS_PAY = 'tips/pay';
export const TIPS_URL = 'tips/url';
export const PROMO_URL = 'promo/get';
export const FEEDBACK_SEND = 'feedback/send';
export const FEEDBACK_TYPES = 'feedback/types';
export const FILE_UPLOAD = 'file/upload';
export const ORDER_CALL = 'order/call';
export const ORDER_STATUS = 'order/status';
export const ORDER_COORDINATES = 'order/coordinates';
