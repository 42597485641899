class Api {
    constructor(options) {
        const { apiVersion, headers } = options || {};

        this.setHeaders(headers);
        this.setOptions({ apiVersion: apiVersion || 'v1' });
    }

    setOptions(options) {
        const { apiVersion } = options || {};

        this.options = { apiVersion };
    }
    setHeaders(headers) {
        this.headers = headers;
    }
    get(url, options) {
        return this._request(url, { method: 'GET', ...options });
    }

    post(url, options) {
        const { body, ...otherOptions } = options || {};

        return this._request(url, {
            method: 'POST',
            body,
            ...otherOptions
        });
    }

    _request(url, options) {
        const { apiVersion } = this.options;
        const { headers = {}, query } = options || {};
        const searchParams = new URLSearchParams(query).toString();
        const requestUrl = searchParams
            // TODO: fix ip
            ? `https://api.avtosushi.net/feedback/${apiVersion}/${url}?${searchParams}`
            : `https://api.avtosushi.net/feedback/${apiVersion}/${url}`;

        return fetch(requestUrl, {
            ...options,
            headers: { ...this.headers, ...headers }
        })
            .then(response => {
                return response.json().then(result => {
                    if (!response.ok) throw new Error(result);

                    return result;
                });
            })
    }
}

export default new Api();
