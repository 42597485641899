import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const AlertModal = (props) => {
    const { show, title, message, onClose } = props;

    return (
        <Modal
            size="sm"
            show={show}
            centered
            onHide={onClose}
        >
            <Modal.Header>
                <Modal.Title className="h5 text-center">{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Row>
                        <Col>
                            <p className="text-center">{message}</p>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Container>
                    <Row>
                        <Col className="d-grid gap-2">
                            <Button
                                variant="secondary"
                                onClick={onClose}
                            >Назад</Button>
                        </Col>
                    </Row>
                </Container>
            </Modal.Footer>
        </Modal>
    );
};

export default AlertModal;
